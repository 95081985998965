* {
  box-sizing: border-box;
  font-family: 'Roboto Mono', 'Segoe UI Emoji', 'Apple Color Emoji', 'Noto Color Emoji', Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  margin: 0;
  height: 100%;
  width: 100%;
}

div, span {
  /* color: #4c3492; */
}

code {
  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
